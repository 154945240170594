<template>
  <div>
    welcome message
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  components: {
  },
}
</script>
